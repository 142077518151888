import React, {useLayoutEffect, useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";

import Layout from "../components/layout";
import { PaginationFooter } from '../components/sections';
import { DisplayHeader, WineryList } from '../components/composites';
import { getFilteredWineries, setExperienceArray } from "../utils";

import './country.scss'


const CountryTemplate = ({data, location}) => {
    const {
        directus: {
            countries: featuredCountry,
            wineries
        }
    } = data;
    const { search } = location;

    const params = new URLSearchParams(search);
    const country = featuredCountry[0] ?? ''
    const listRef = useRef(null);
    const countryName = country?.name ?? '';
    const description = country?.description ?? null;
    const backgroundColor = country?.color ?? null;
    const image = country?.featured_image ?? null;
    const wineryLength = wineries.length;
    const pageNumber = Number(params.get("page") ?? 1);
    const startWineryDisplay = (pageNumber - 1) * 18;
    const endWineryDisplay = pageNumber * 18;

    const primaryWinerySlug = country?.primary_winery?.slug ?? wineries[0].slug;
    const secondaryWinerySlug = wineryLength > 1 ? (country?.secondary_winery?.slug ?? wineries[1].slug) : null;

    const primaryWinery = wineries.find(({slug}) => slug === primaryWinerySlug)
    const secondaryWinery = wineries.find(({slug}) => slug === secondaryWinerySlug)

    const filterPreppedArray = useMemo(() => setExperienceArray(wineries), [wineries])
    const [filteredWineries, setFilteredWineries] = useState(wineries);
    const [wineriesToDisplay, setWineriesToDisplay] = useState(filteredWineries.slice(startWineryDisplay, endWineryDisplay));

    useLayoutEffect( () => {
        const filteredResults = getFilteredWineries(location, filterPreppedArray)
        setFilteredWineries(filteredResults)
        setWineriesToDisplay(filteredResults.slice(startWineryDisplay, endWineryDisplay))
    }, [filterPreppedArray, location, search])

    return(
        <Layout className='country-template'
                title={`${countryName} Vineyards`}
                currentPage='country'
                location={location}
                description={description}
                image={image?.filename_disk ?? null}
        >
            <DisplayHeader
                title={countryName}
                backgroundColor={backgroundColor}
                wineryCount={filteredWineries.length}
                image={image}
                location={location}
                hiddenFilters={['Country', 'Grape']}
                screenReadText='Find wineries in'
            >
                <div ref={listRef}/>
                <WineryList wineries={wineriesToDisplay} primaryFeatureWinery={primaryWinery} secondaryFeatureWinery={secondaryWinery} search={search}/>
            </DisplayHeader>

            <PaginationFooter setDataToDisplay={setWineriesToDisplay} data={filteredWineries} scrollToRef={listRef} pageNumber={pageNumber} location={location}/>
        </Layout>
    )
}

CountryTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

CountryTemplate.defaultProps = {

}

export const CountryQuery = graphql`
  query($slug: String!) {
    directus {
      countries(
        filter: {slug: {_eq: $slug} }
      ) {
        name
        slug
        description
        featured_image {
            id
            filename_disk
            imageFile {
           childImageSharp {
             gatsbyImageData
            }
           }
         }
      }
      wineries(
       limit: -1
       sort: ["-vind_score"]
       filter: {status: {_eq: "published"}, country: {slug: {_eq: $slug}}}
      ) {
         additional_images {
            directus_files_id {
              filename_disk
              }
         }
         slug
         featured_image {
            filename_disk
         }
         google_total_reviews
         google_rating
         name
         country {
            name
            slug
         }
         region
         summary
         accommodation
         cellar_door
         events
         restaurant
         tours
         tastings
         tags {
            tags_id {
              slug
            }
          }  
       }
    }
  }
`

export default CountryTemplate